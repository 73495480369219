<template>
  <div class="form">
    <div class="wrapper illustration illustration_form" v-if="!isMobile || !$store.state.popup.id && (!isMobile || !$store.state.is_popup_shown)">
      <Backlink title="information" service="refMortgage" backlink="/refinance-mortgage/members" />
      <div class="content">
        <div class="content__container">
          <p class="promo promo_blocks2">{{ "rm_s1p1_title" | localize }}</p>
          <form @submit.prevent="submitHandler">
            <div class="form__group">
              <v-text-field
                v-model="balance"
                class="input_number"
                inputmode="numeric"
                pattern="[0-9]*"
                @blur="$v.balance.$touch()"
                @change="checkForm($v.balance)"
                @keypress="onKeyPressHandler"
                @keyup="onKeyUpPriceHandler('balance', balance)"
                :class="{ invalid: $v.balance.$dirty && !$v.balance.required }"
                :hint="
                  ($v.balance.$dirty && !$v.balance.required
                    ? 'form_reqiered'
                    : 'empty') | localize
                "
                :label="'rm_s1p1_label_1' | localize"
                :placeholder="'s2p4_placeholder_1' | localize"
                append-icon="icon-₪"
                ref="balance"
                outlined
              ></v-text-field>

              <v-text-field
                v-model="cost"
                class="input_number"
                inputmode="numeric"
                pattern="[0-9]*"
                @blur="$v.cost.$touch()"
                @change="checkForm($v.cost)"
                @keypress="onKeyPressHandler"
                @keyup="onKeyUpPriceHandler('cost', cost)"
                :class="{ invalid: $v.cost.$dirty && !$v.cost.required }"
                :hint="
                  ($v.cost.$dirty && !$v.cost.required
                    ? 'form_reqiered'
                    : 'empty') | localize
                "
                :label="'rm_s1p1_label_2' | localize"
                :placeholder="'s2p4_placeholder_1' | localize"
                append-icon="icon-₪"
                ref="cost"
                outlined
              ></v-text-field>
            </div>

            <div class="form__group">
              <v-autocomplete
                v-model="bank"
                :items="banks"
                @blur="$v.bank.$touch()"
                @change="checkForm($v.bank)"
                :class="{ invalid: $v.bank.$dirty && !$v.bank.required }"
                item-text="name"
                item-value="value"
                cache-items
                :label="'rm_s1p1_label_3' | localize"
                :placeholder="'s4p11_placeholder_2' | localize"
                append-icon="icon-arrow-down"
                ref="bank"
                outlined
              ></v-autocomplete>

              <v-select
                v-model="object"
                @blur="$v.object.$touch()"
                @change="checkForm($v.object)"
                :items="getList('s2p4_select_1_value_', 4)"
                :class="{ invalid: $v.object.$dirty && !$v.object.required }"
                item-text="name"
                item-value="value"
                :label="'rm_s1p1_label_4' | localize"
                :placeholder="'select' | localize"
                :menu-props="{ offsetY: true }"
                append-icon="icon-arrow-down"
                ref="object"
                outlined
              ></v-select>
            </div>

            <div class="error__container">
              <p class="error" v-if="error">{{ error | localize }}</p>
            </div>

            <button
              type="button"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
              @click="submitHandler"
            >
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>

    <PopupText/>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VueCookies from 'vue-cookies'
import Backlink from '@/components/base/Backlink.vue'
import PopupText from '@/components/PopupText.vue'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'
import { saveMixin } from '@/mixins/saveMixin.js'

const refMortgage = VueCookies.get('refMortgage') || {}

export default {
  name: 'Refinance_mortgage_refinancing_about',
  components: { Backlink, PopupText },
  mixins: [formMixin, commonMixin, numberMixin, saveMixin],
  data: () => ({
    balance: refMortgage.balance || null,
    cost: refMortgage.cost || null,
    bank: refMortgage.bank || null,
    object: refMortgage.object || null,
    error: null
  }),
  validations: {
    balance: { required },
    cost: { required },
    bank: { required },
    object: { required }
  },
  created () {
    this.balance = refMortgage.balance ? this.formatNumber(refMortgage.balance) : null
    this.cost = refMortgage.cost ? this.formatNumber(refMortgage.cost) : null
    this.$store.commit('togglePopupText', { id: 14, withTitle: false, type: 'popup_info' })
  },
  computed: { loaded_banks () { return this.$store.state.banks} },
  watch: { loaded_banks () { this.setBanks() } },
  methods: {
    submitHandler () {
      this.balance = this.parseNumber(this.balance)
      this.cost = this.parseNumber(this.cost)

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }

      this.success = true
      refMortgage.balance = this.balance
      refMortgage.cost = this.cost
      refMortgage.bank = this.bank
      refMortgage.object = this.object

      this.$cookies.set('refMortgage', refMortgage)
      this.saveServiceInfo('refMortgage', false, { realty_type: this.object })
      this.saveUserService('refMortgage', '/refinance-mortgage/programs', { amount: parseInt(this.balance, 10) })
    }
  }
}
</script>
